<template>
    <div class="buy-box">
        <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane :key="app.id" :tab="app.name" v-for="app in appList">
                <div v-if="packageList.length == 0" class="flex-nowrap-center-center not-data">
                    暂无数据
                </div>
                <div class="package-shop" v-loading="loading">
                    <div class="package-out" v-for="(item,i) in packageList" :key="i">
                        <el-card>
                            <div class="package-inside">
                                <div class="title-text">
                                    <img :src="require(`@/assets/images/buy.png`)" alt="">
                                    <span class="text-init">{{ item.name }}</span>
                                </div>
                                <div class="text-box">
                                    <span class="title-box">服务商</span>
                                    <span class="val-box">{{ item.amountName }}</span>
                                </div>
                                <!-- <div class="text-box">
                                    <span class="title-box">接口线路</span>
                                    <span class="val-box">{{ item.interfaceName }}</span>
                                </div> -->
                                <div class="text-box" v-if="item.type != 3">
                                    <span class="title-box">分钟数</span>
                                    <span class="val-box">{{ item.minutes }}分钟</span>
                                </div>
                                <div class="text-box" v-if="item.type == 3">
                                    <span class="title-box">次数</span>
                                    <span class="val-box">{{ item.num }}次</span>
                                </div>
                                <div class="text-box">
                                    <span class="title-box">有效期</span>
                                    <span class="val-box">{{ item.days }}天</span>
                                </div>
                                <div class="text-box">
                                    <span class="title-box"></span>
                                    <span class="money-box">￥{{ item.price }}</span>
                                </div>
                                <div class="buy-button">
                                    <el-button class="buttom-color" @click="buyPackage(item.id)">
                                        <span class="buy-text">购买</span>
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>
        <div class="search-box">
            <el-input
                v-model="formData.name"
                placeholder="套餐名称"
                size="mini"
                @input="searchInput"
                prefix-icon="el-icon-search"
            />
        </div>
    </div>
    <div class="page-box">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            background
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
import resources from "@/api/web/resources"
import packet from '@/api/web/packet'
import app from '@/api/web/app'
export default {
    components:{

    },
    data(){
    return{
        loading:false,
        activeKey:'1',
        currentPage:1,
        total:0,
        appList:[],
        packageList:[],
        formData:{
            page:1,
            pageSize:10,
            name:'',
            type:''
        },
    }
    },
    methods:{
        getAppList(){
            app.appQuery({}).then((res) => {
                this.appList = res.data
                this.appList.splice(0, 1)
                this.activeKey = this.appList[0].id+''
            })
        },
        getPacketList(type){
            this.formData.type = type
            this.loading = true
            packet.listUser({
                ...this.formData
            }).then(res=>{
                this.packageList = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val){
            this.formData.pageSize = val
            this.getPacketList(this.activeKey)
        },
        handleCurrentChange(val){
            this.formData.page = val
            this.currentPage = val
            this.getPacketList(this.activeKey)
        },
        searchInput(){
            this.getPacketList(this.activeKey)
        },
        buyPackage(id){
            this.$confirm('将购买该套餐, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                resources.shopUser({
                    packetId:id,
                    uid:this.$store.getters.user.uid
                }).then(res=>{
                    this.$notify({
                        title: '成功',
                        message: '购买套餐成功',
                        type: 'success'
                    });
                })
            }).catch(() => {
                this.$notify.info({
                    title: '通知',
                    message: '已取消购买!'
                });
            });
        }
    },
    watch:{
        activeKey(newV,oldV){
            console.log(newV)
            this.getPacketList(newV)
        }
    },
    mounted() {
        this.getPacketList(this.activeKey)
    },
    created() {
        this.getAppList()
    },
}
</script>
<style lang="scss" scoped>
    .buy-box{
        position: relative;
        margin-right: 1rem;
    }
    .search-box{
        position: absolute;
        right: 32px;
        top: 8px;
    }
    .package-shop{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .package-out{
        width: 390px;
        margin-right: 16px;
        margin-bottom: 26px;
    }
    .package-inside{
        box-sizing: border-box;
    }
    .page-box{
        position: absolute;
        right: 32px;
        bottom: 32px;
        background: #fff;
        padding: 8px;
        border-radius: 8px;
    }
    .buy-button{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .text-box{
        height: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }
    .title-box{
        color: #666666;
        width: 100px;
    }
    .val-box{
        width: 150px;
        text-align: right;
        color: #2C2C2C;
        font-weight: bold;
        font-size: 16px;
    }
    .money-box{
        width: 150px;
        text-align: right;
        color: #FF4040;
        font-weight: bold;
        font-size: 20px;;
    }
    .text-init{
        position: absolute;
        font-size: 20px;
        font-weight: 700;
        color: #233BB6;
    }
    .title-text{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .buy-text{
        display: inline-block;
        width: 270px;
        text-align: center;
    }
    .buttom-color{
        background: #637DFF;
        color: #fff;
    }
    .not-data{
        height: 60px;
        color: #909399;
    }
</style>